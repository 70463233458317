<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table-heading, list-item"></v-skeleton-loader>
      <v-row class="mx-2">
        <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader type="table-tfoot"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Staff
        <v-divider vertical class="mx-3"></v-divider>
        <v-btn
          icon
          color="primary"
          dark
          @click="
            $router.push({
              name: 'PageStaffListTable',
            })
          "
        >
          <v-icon> mdi-list-box-outline </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Create')"
        >
          <v-icon left> mdi-plus </v-icon> New Staff
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="filterData"
            background-color="transparent"
          >
            <v-tab :tab-value="1">All</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="2">Current</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="3">Past</v-tab>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-card-title class="primary--text">
        <v-text-field
          dense
          filled
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-menu
          :close-on-content-click="true"
          :nudge-width="200"
          offset-y
          bottom
          max-width="180"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="ms-2">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>

          <v-card outlined elevation="0" class="imso-menu">
            <v-list class="pa-0">
              <v-list-item
                link
                @click="
                  status = 1;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="1"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                @click="
                  status = 2;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="2"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>Active</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                @click="
                  status = 3;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="3"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>Deactivated</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-card-title>

      <v-data-iterator
        :items="data"
        :search="search"
        class="ma-4"
        :items-per-page="12"
        :footer-props="{
          itemsPerPageOptions: [6, 12, 24, -1],
        }"
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="(item, i) in props.items"
              :key="i"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <v-card
                class="pa-4 d-flex flex-column justify-space-between"
                outlined
                height="100%"
              >
                <v-sheet class="d-flex justify-end" color="transparent">
                  <v-chip
                    dark
                    class="mx-1"
                    :color="item.is_active ? 'green' : 'red'"
                    x-small
                  >
                    {{ item.is_active ? "Active" : "Deactivated" }}
                  </v-chip>
                </v-sheet>

                <v-list-item class="d-flex justify-center mt-3">
                  <v-list-item-avatar
                    :color="
                      '#' + ((Math.random() * 0xffffff) << 0).toString(16)
                    "
                    size="100"
                    class="mr-0 d-flex justify-center"
                    style="position: relative"
                  >
                    <div class="white--text text-h3" style="position: absolute">
                      {{ item.first_name.charAt(0).toUpperCase()
                      }}{{ item.last_name.charAt(0).toUpperCase() }}
                    </div>
                    <v-img
                      :src="item.profile"
                      alt="item.first_name"
                      v-if="item.profile"
                    />
                  </v-list-item-avatar>
                </v-list-item>

                <v-list-item class="text-center">
                  <v-list-item-content class="pt-0">
                    <v-sheet class="text-h6" color="transparent">
                      {{ item.first_name }} {{ item.last_name }}
                    </v-sheet>
                    <v-sheet color="transparent text--secondary">
                      {{ item.designation }}
                    </v-sheet>
                  </v-list-item-content>
                </v-list-item>

                <v-sheet
                  class="d-flex flex-column justify-center"
                  color="transparent"
                >
                  <v-text-field
                    full-width
                    color="primary"
                    class="my-2"
                    prepend-inner-icon="mdi-email-outline"
                    hide-details
                    flat
                    dense
                    outlined
                    readonly
                    filled
                    :value="item.primary_email"
                    @click="copyToClipboard(item.primary_email)"
                    @click:prepend-inner="copyToClipboard(item.primary_email)"
                  ></v-text-field>
                  <v-text-field
                    full-width
                    color="primary"
                    class="my-3"
                    prepend-inner-icon="mdi-phone"
                    hide-details
                    flat
                    dense
                    outlined
                    readonly
                    filled
                    :value="item.primary_mobile_no"
                    @click="copyToClipboard(item.primary_mobile_no)"
                    @click:prepend-inner="
                      copyToClipboard(item.primary_mobile_no)
                    "
                  ></v-text-field>
                  <v-btn
                    class="mt-2"
                    block
                    depressed
                    color="primary"
                    @click="viewItem(item)"
                  >
                    View Profile
                  </v-btn>
                </v-sheet>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreStaff,
  syncOfflineStaffChanges,
} from "@/services/staffService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    search: "",
    data: [],
    dataX: [],
    tab: 2,
    status: 1,
  }),
  async created() {
    if (this.$_checkPermission(this.auth.permissions, "Manage Staff", "Read")) {
      this.loading = true;
      await syncOfflineStaffChanges();
      this.dataX = await fetchAndStoreStaff();
      this.filterData();
      this.loading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewStaff",
        params: { uuid: item.uuid },
        query: { bc: item.first_name + " " + item.last_name },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewStaff" });
    },
    copyToClipboard(text) {
      this.$store.commit("setError", {
        isError: false,
        error: "Copied to clipboard!",
      });
      navigator.clipboard.writeText(text);
    },
    filterData() {
      this.data = [];

      if (this.tab == 3) {
        this.dataX.forEach((staff) => {
          if (staff.left_date) {
            if (this.status == 3) {
              if (!staff.is_active) this.data.push(staff);
            } else if (this.status == 2) {
              if (staff.is_active) this.data.push(staff);
            } else {
              this.data.push(staff);
            }
          }
        });
      } else if (this.tab == 2) {
        this.dataX.forEach((staff) => {
          if (!staff.left_date) {
            if (this.status == 3) {
              if (!staff.is_active) this.data.push(staff);
            } else if (this.status == 2) {
              if (staff.is_active) this.data.push(staff);
            } else {
              this.data.push(staff);
            }
          }
        });
      } else {
        this.dataX.forEach((staff) => {
          if (this.status == 3) {
            if (!staff.is_active) this.data.push(staff);
          } else if (this.status == 2) {
            if (staff.is_active) this.data.push(staff);
          } else {
            this.data.push(staff);
          }
        });
      }
    },
  },
};
</script>